import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
const propTypes = {
    data: PropTypes.shape({
        domain: PropTypes.string,
        category: PropTypes.string,
        content: PropTypes.string
    }).isRequired,
    children: PropTypes.node
}

const QuotationItem = ({
    className,
    data,
    children,
    ...props
}) => {
    const classNameList = classNames(
        'tiles-item',
        className
    );
    return (
        <>
            {(data.domain || data.content) &&
                <div className={classNameList} data-reveal-delay="200">
                    <div className="tiles-item-inner">
                        <div className="testimonial-item-content">
                            <p className="text-sm mb-0">
                                — {data.content}
                            </p>
                        </div>
                        <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                            <span className="testimonial-item-name text-color-high">{data.domain}</span>
                            <span className="text-color-low"> / </span>
                            <span className="testimonial-item-link">
    
                                <Link to={data.url}  >{data.category}</Link>
                            </span>
                        </div>
                    </div>
                </div>
            }
        </>

    );
}

QuotationItem.propTypes = propTypes;

export default QuotationItem;