import React from 'react';
//import { withTranslation, Trans } from 'react-i18next';

import SpingHouse from '../pages/spring/index';
//const I18nContactItem = withTranslation()(ContactItem);

const Contact = () => {

    return (
        <>
            <SpingHouse />
        </>
    );
}

export default Contact;