import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
 

 
const RouteChangeRefresher = ({
    history,
  }) => {
    const location = useLocation();
    useEffect(() => {
      const unlisten = history.listen((location, action) => {
        // 当路由即将改变时，刷新页面
        window.location.reload();
      });
   
      return () => unlisten(); // 清理函数，在组件卸载时取消监听
    }, [location]);
    return ("");
  }
export default RouteChangeRefresher;