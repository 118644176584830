import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import CertFragment from '../../components/layout/fragment/CertFragment';

import { useTranslation } from 'react-i18next';

const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Certificate = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const { t } = useTranslation();

    const outerClasses = classNames(
        'section about',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );


    return (
        <section
            {...props}
            className={outerClasses}>
            <div className="container">
                <div className="features-tiles-inner section-inner pt-12 center-content">
                    <h3 className="mt-16 mb-16">{t("about.certifi.title")}</h3>
                    <div >
                    {t("about.certifi.summary")}
                    </div>
                    <div className='tiles-wrap'>
                        <CertFragment imgSrc={"/images/zz11.png"} height={306} certName={t("about.certifi.category.credit")} />
                    </div>
                    <div className='tiles-wrap'>
                        <CertFragment imgSrc={"/images/qxxy.png"} height={641} certName={t("about.certifi.category.advanced")} />
                        <CertFragment imgSrc={"/images/AIROTO_1.png"} height={651} certName={t("about.certifi.category.airoto")} />
                    </div>
                    <div className='tiles-wrap'>
                        <CertFragment imgSrc={"/images/zz1.png"} height={639} certName={t("about.certifi.category.aaacredit")} />
                        <CertFragment imgSrc={"/images/zz2.png"} height={639} certName={t("about.certifi.category.contract")}/>
                        <CertFragment imgSrc={"/images/zz3.png"} height={639} certName={t("about.certifi.category.quality")} />
                        <CertFragment imgSrc={"/images/zz4.png"} height={639} certName={t("about.certifi.category.entrepreneur")} />
                        <CertFragment imgSrc={"/images/zz5.png"} height={639} certName={t("about.certifi.category.qua-cre")} />
                        <CertFragment imgSrc={"/images/zz6.png"} height={639} certName={t("about.certifi.category.cre-manage")} />
                        <CertFragment imgSrc={"/images/zz7.png"} height={639} certName={t("about.certifi.category.mon-cre")} />
                        <CertFragment imgSrc={"/images/zz8.png"} height={639} certName={t("about.certifi.category.supplier")} />
                        <CertFragment imgSrc={"/images/zz9.png"} height={639} certName={t("about.certifi.category.Bidding")} />
                        <CertFragment imgSrc={"/images/zz10.png"} height={639} certName={t("about.certifi.category.service")} />
                    </div>
                </div>
            </div>
        </section>
    );
}

Certificate.propTypes = propTypes;
Certificate.defaultProps = defaultProps;

export default Certificate;