import React from 'react';


import AddQuota from '../pages/quotations/AddQuot';

const AddQuotation = () => {

    return (
        <>
            <AddQuota  />
        </>
    );
}

export default AddQuotation;