import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );
  const {t}=useTranslation();
  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        {/**
        <li>
          <a href="https://www.buyhpy.com/">{t('header.mall')}</a>
        </li>
         */}
        <li>
          <Link to="/web/tender">{t('header.solutions')}</Link>
        </li>
        <li>
          <Link to="/web/about">{t('header.abouts')}</Link>
        </li>
        <li>
          <Link to="/web/privacy">{t('header.privacy')}</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;