import React from 'react';
//import { withTranslation, Trans } from 'react-i18next';

import ContactItem from '../pages/contact/ContactItem';
//const I18nContactItem = withTranslation()(ContactItem);

const Contact = () => {

    return (
        <>
            <ContactItem />
        </>
    );
}

export default Contact;