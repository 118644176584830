import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { BackTop } from 'antd';
import VerticalAlignTopOutlined from '@ant-design/icons';

import '../assets/scss/style.scss';

const LayoutWhite = ({ children }) => (

    <div className='common-layout'>
        <Header navPosition="right" className="reveal-from-bottom" is-default-logo={true} />
        <main className="body-white site-content">
            {children}
        </main>
        <Footer is-default-logo={true} />
        <div>
            <BackTop visibilityHeight={300} />
            <div ><VerticalAlignTopOutlined > </VerticalAlignTopOutlined></div>
        </div>
    </div>
);

export default LayoutWhite;  