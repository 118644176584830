import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

import { useTranslation } from 'react-i18next';
const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const  PrivacyBody= ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const { t } = useTranslation();
  
    const outerClasses = classNames(
        'about section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section
            {...props}
            className={outerClasses}>
            <div className="container">
                <div className="features-split">
                    <h3 className="mt-48 mb-16 center-content">{t("privacy.title")}</h3>
                    <div className='container-xs'>
                        <p className='mt-0'>
                        {t("privacy.updateTime")}
                        <br/>{t("privacy.excuteTime")}
                        </p>
                        
                        <p className='mt-0'>
                        1.提示条款<br/>
<br/>欢迎您使用深圳红壁虎科技有限公司的产品和服务！
<br/>深圳红壁虎科技有限公司（以下称“红壁虎科技”）深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，遵循合法、正当、必要和诚信原则处理您的个人信息。因此制定本《隐私政策》（以下称“本政策”）以便您充分了解，在您使用红壁虎科技的产品或服务时，我们会如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
<br/>在您使用前，请您务必认真阅读本政策，充分理解各条款内容，您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。如有任何疑问，请联系我们。

                        </p>
                        <p className='mt-0'>
                        本政策将帮助您了解以下内容：
                        <br/>1.1.我们如何收集和使用您的个人信息
                        <br/>1.2.我们如何使用COOKIES或同类技术
                        <br/>1.3.我们如何共享、转让、公开披露您的个人信息
                        <br/>1.4.我们如何保护您的个人信息
                        <br/>1.5.您如何管理您的个人信息
                        <br/>1.6.我们如何处理未成年人的个人信息
                        <br/>1.7.本隐私政策如何更新
                        <br/>1.8.如何联系我们

                        </p>
                        <p className='mt-0'>
                        2.我们如何收集和使用您的个人信息
                        <br/>2.1.个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
                        <br/>2.2.我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用的过程中主动提供或因使用本而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。在您注销账号时，我们将停止使用并删除上述信息。
                        <br/>2.3.您必须授权我们收集和使用您个人信息的情形
                        <br/>2.3.1.我们的产品或服务包括一些核心功能，我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品和服务。这些功能包括：
                        <br/>2.3.1.1.账户注册、登陆与验证
                        <br/>当您注册账户时，您至少需要向我们提供您准备使用的账户名、密码、您本人的手机号码、电子邮箱地址，我们将通过发送短信验证码或邮件的方式验证您的身份信息是否有效。
<br/>2.3.1.2.商品购买及支付功能
<br/>为方便向您交付商品或服务，您至少需要按照提示向我们提供收货人个人身份信息（包括姓名、收货地址、邮政编码、联系电话）和支付状态信息。如果您拒绝提供此类信息，我们将无法完成相关交付服务。
<br/>如在您使用本产品或服务的过程中产生支付结算，您可以选择我们的关联方或与我们合作的第三方支付机构（包括支付宝、微信、银联等支付通道，以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您对个人信息，但我们需要将您的订单号与交易金额信息同这些支付机构共享以实现其确认您的支付指令并完成支付。
<br/>2.3.1.3.客服功能
<br/>如果您联系我们的客服，我们可能需要您提供必要的个人信息进行身份验证，以保证您的帐号安全。为根据您的诉求提供服务，经过您的授权，人工客服人员需要再您授权范围内查询或检验您的相关信息，我们将尽可能采取技术和管理措施保障您的信息安全，并在必要范围内进行使用。我们可能会保存您的联系方式（您使用或主动提供的
<br/>手机号、微信号、QQ号或其他联系方式），您与我们的通信记录和内容，以及其他必要信息，以便为您提供和记录客户服务。
<br/>2.3.1.4.安全保障功能
<br/>为提高您使用我们产品和服务的安全性，保护您或其他用户的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒等安全风险，更准确地识别违反法律法规等情况，我们可能会收集、使用或整合您的账户信息、交易信息、设备信息，来综合判断您的账户和交易风险，进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
<br/>2.4.您可自主选择提供的个人信息的情形
<br/>2.4.1.为了您使用本产品或服务时，更加便捷或更有乐趣，从而提升您在使用时的体验，我们的以下扩展功能可能会手机和使用您的个人信息。如果您不提供这些个人信息，您依然可以使用本产品的核心功能，但是您可能无法使用我们为您提供的扩展功能或您需要重复填写一些信息。这些扩展功能包括：
<br/>2.4.1.1.个性化推送功能
<br/>为了向您展示、推荐相关性更高的信息，提供更契合您要求的服务，我们会收集、使用您的个人信息并通过计算机算法模型自动计算、预测您的偏好，以匹配您可能感兴趣的产品或服务。请知悉，个性化推荐算法并不会识别特定自然人的真实身份，仅是基于您的行为和特征提供相关性更高与更丰富的信息。

<br/>您可以通过本应用设置开启个性化推荐权限，以便查看针对您个人特征的产品和服务且。
<br/>你也可以通过本应用设置关闭对应的个性化推荐权限，当您关闭后，我们不会基于个性化推送目的处理您的个人信息。
<br/>2.4.1.2.基于地理位置的扩展功能
<br/>如您使用基于地理位置的功能或服务，我们需要您授权我们访问您的位置权限，并收集您的位置信息。您的位置信息属于敏感个人信息，请您谨慎提供，您可以通过手机应用设置关闭我们的此类功能和服务。
<br/>2.4.1.3.基于通讯录信息的扩展功能
<br/>在您同意开启通讯录权限后，我们将收集您的通讯录信息以方便您在使用我们的产品和服务时不需再手动输入您通讯录中联系人的信息。
<br/>2.4.1.4.基于摄像头（相机）的扩展功能
<br/>您可以使用这项扩展功能完成视频拍摄、拍照、扫码以及人脸识别登录的功能。当您使用人脸识别登录时我们会收集您的面部信息，且在您授权范围内限制使用。未来我们可能会将人脸识别技术应用于更多场景，届时我们会再次与您确认您是否愿意我们使用您的面部信息来实现这些扩展功能。
<br/>2.4.1.5.基于图片或视频上传的扩展功能
<br/>您可以在手机应用设置中开启上传您的照片或视频权限，以便需要时，你可以上传的图片或视频以用于对应的信息资料提交等用途。
<br/>2.4.1.6.使用第三方账户登陆
<br/>当您使用第三方帐号（如微信、QQ、微博、Apple ID）登录本应用时，您需要授权在符合相关法律法规的前提下读取并共享获得您在该第三方平台上公布、记录的公开信息（包括昵称、头像、地区、性别、联系邮箱中的一项或几项），以便将您的第三方帐号与本应用帐号进行绑定，并记住您作为我们用户的登录身份。
<br/>2.4.1.7.补充账户信息
<br/>在您注册完成账户后，您可以在我的基本资料一栏中继续填写您的信息，包括您的生日，性别，单位名称。您若不提供这些信息，将不会影响您对具体技术服务的使用。
<br/>2.4.1.8.修改账户密码
<br/>当需要修改帐号密码时，请您输入新密码，以完成重置。当您需要修改手机号时，请您输入新手机号和短信验证码。您可以自愿在设置页面绑定您的邮箱，此时，您需要提供您的邮箱帐号和我们发送给您的短信验证码进行验证操作。如您需设置信任设备，您需要向我们提供您正在使用的设备名称、设备类型、最后使用时间。
<br/>2.4.1.9.接入第三方应用
<br/>当您在本应用中使用接入的第三方应用时，我们为提供对接技术支持等目的，可能从此类第三方应用的开发者及其技术服务提供商处获取您必要的个人信息，例如设备信息、操作行为日志信息。如果涉及您的敏感个人信息，我们将单独向您取得授权。如您拒绝我们收集相关个人信息，可能导致第三方应用的全部或部分功能无法实现，但是不影响您使用本应用的其他功能。
<br/>此类第三方应用的开发者及技术服务提供商可能依据其提供的隐私政策直接收集、使用您的个人信息，但我们不会获取这些个人信息。建议您在接受相关服务和提供个人信息前阅读并确认理解该等隐私政策。
<br/>2.5.您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意
<br/>2.5.1.与国家安全、国防安全有关的。
<br/>2.5.2.与公共安全、公共卫生、重大公共利益有关的。
<br/>2.5.3.与犯罪侦查、起诉、审判和判决执行等有关的。
<br/>2.5.4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。
<br/>2.5.5.所收集的个人信息是个人信息主体或监护人自行向社会公众公开的。
<br/>2.5.6.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。
<br/>2.5.7.根据您的要求签订合同所必需的。
<br/>2.5.8.用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障。
<br/>2.5.9.为合法的新闻报道所必需的。
<br/>2.5.10.学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
<br/>2.5.11.法律法规规定的其他情形。
<br/>2.6.我们从第三方获得您个人信息的情形
<br/>2.6.1.我们可能从第三方获取您授权共享的个人信息，我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。

                        </p>
                        <p className='mt-0'>
                        3.我们如何使用COOKIES或同类技术
<br/>3.1.我们可能通过使用COOKIES或同类技术获取和使用您的信息，并将该信息存储为日志信息。
<br/>3.2.通过使用COOKIES，我们向用户提供简单易行并富个性化的网络体验。一个COOKIES是少量的数据，它们从一个网络服务器送至您的浏览器并存在计算机硬盘上。我们使用COOKIES是为了让其用户可以受益。比如，为使得您的登录过程更快捷，您可以选择把用户名存在一个COOKIES中。这样下次当您要使用应用的服务时能更加方便快捷。COOKIES能帮助我们确定您连接的页面和内容，您在该产品特定服务上花费的时间和您所选择的服务。
<br/>3.3.COOKIES使得我们能更好、更快地为您服务，并且使您在该产品和服务上的经历更富个性化。您可以通过浏览器或用户选择机制拒绝或管理COOKIES或同类技术。但请您注意，如果您停用COOKIES或同类技术，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。
<br/>3.4.我们使用自己的COOKIES或同类技术，可能用于以下用途：
<br/>3.4.1.记住您的身份。
<br/>3.4.2.分析您使用我们服务的情况。
<br/>3.4.3.广告优化。
<br/>3.5.如您希望禁用COOKIES，您可以对浏览器进行配置：选择"拒绝COOKIES"或"当网站发送COOKIES时通知您"。鉴于我们的服务是通过支持COOKIES来实现的，完成关闭COOKIES的操作后，您的访问微博可能受到影响或不能充分获得我们的服务。您不禁用COOKIES时，可能会得到提示，例如是否在下一次进入此网站时保留用户信息以便简化登录手续（如自动登录）。

                        </p>
                        <p className='mt-0'>
                        4.我们如何共享、转让或公开披露您的个人信息
<br/>4.1.委托处理
<br/>4.1.1.如我们委托第三方处理您的个人信息的，我们将会与受托人约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。
<br/>4.2.共享
<br/>4.2.1.我们在遵守“合法正当、最小必要、目的明确原则”的前提下共享您的个人信息。同时，我们将对个人信息的共享活动事先进行个人信息保护影响评估，对输出形式、流转、使用等采取有效的技术保护措施。在合作协议层面，严格要求合作伙伴的信息保护义务与责任，与业务合作伙伴在合作前签署关于数据安全的保护协议。
<br/>我们制定了《第三方共享清单》向您清晰、简洁的介绍我们主要的有关您个人信息共享情况。
<br/>4.2.2.我们不会与本公司之外的任何第三方共享您的信息，但以下情形除外：
<br/>（1）事先获得您明确的同意或授权。
<br/>（2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求对外共享您的信息。
<br/>（3）只有共享您的个人信息，才能实现我们的产品和服务的核心功能或提供您需要的服务。
<br/>（4）为实现基于我们向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账户与交易安全等，我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私政策的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
<br/>（5）我们可能会向合作伙伴等第三方共享您的信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
<br/>（6）基于学术研究而提供。
<br/>（7）根据与您签署的单项服务协议或其他的法律文件约定所提供。
<br/>4.3.转让
<br/>4.3.1.转让是指将个人信息控制权向其他公司、组织或个人转移的过程。
<br/>4.3.2.我们不会与本公司以外的任何第三方转让您的个人信息，但以下情形除外：
<br/>（1）事先获得您明确的同意或授权。
<br/>（2）随着我们业务的持续发展，在我们发生合并、收购或破产清算情形时，您的信息有可能作为此类交易的一部分而被转移。如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织、个人继续受本政策的约束，否则我们将要求该公司、组织、个人重新向您征求授权同意。
<br/>4.4.公开披露
<br/>4.4.1.我们不会与本公司以外的任何第三方公开披露您的个人信息，但以下情形除外：
<br/>（1）我们可能会根据法律法规、诉讼、争议解决的需要，或按行政、司法机关依法提出的要求公开披露您的个人信息。
<br/>（2）根据您的需求，在您明确同意的披露方式下披露您所指定的信息。

                        </p>
                        <p className='mt-0'>
                        5.我们如何保护您的个人信息
<br/>5.1.我们一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术及组织和管理等保护措施以最大程度降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。
<br/>5.1.1.数据安全技术措施
<br/>5.1.1.1.我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改,避免数据的损坏或丢失。
<br/>5.1.1.2.我们的网络服务采取了传输层安全协议等加密技术，通过https等方式提供浏览服务，确保用户数据在传输过程中的安全。
<br/>5.1.1.3.我们采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。
<br/>5.1.1.4.在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。
<br/>5.1.1.5.我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。
<br/>5.1.1.6.我们采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。
<br/>5.1.2.我们为保护个人信息采取的其他安全措施
<br/>5.1.2.1.我们通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。
<br/>5.1.2.2.我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
<br/>5.1.2.3.我们建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
<br/>5.1.2.4.我们会举办安全和隐私保护培训课程，加强员工的安全意识以及对于个人信息保护政策和相关规程的认识。
<br/>5.2.请您知悉，虽然我们将尽力确保您发送给我们的任何信息的安全性，但互联网环境并非百分之百安全，我们亦不对由此产生或与之相关的任何风险、损失承担责任。
<br/>5.3.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关程序以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。

                        </p>
                        <p className='mt-0'>
                        6.您如何管理您的个人信息
<br/>6.1.我们非常重视您对个人信息的管理，并尽全力保障您对于个人信息的访问、更正、删除、撤回同意以及其他法定权利，使您拥有充分的能力维护您的隐私和个人信息安全。
<br/>6.2.访问和更正您的个人信息
<br/>6.2.1.除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：
<br/>（1）账户信息：
<br/>（2）浏览信息：
<br/>（3）评论信息：
<br/>6.2.2.对于您在使用我们的产品和服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
<br/>6.3.删除您的个人信息
<br/>6.3.1.在以下情形中，您可以通过本政策载明的方式向我们提出删除个人信息的请求：
<br/>（1）如果我们处理个人信息的行为违反法律法规。
<br/>（2）如果您不再使用我们的产品和服务，或您注销账号。
<br/>（3）如果我们停止提供产品和服务，或保存期限已届满。
<br/>（4）如果您撤回同意。
<br/>（5）如果我们处理个人信息的目的已实现、无法实现或为实现处理目的不再必要。
<br/>6.4.改变或撤回您的同意
<br/>6.4.1.您可以通过删除信息、关闭设备功能、在
<br/>产品或服务名称
<br/>网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
<br/>6.4.2.请您理解
<br/>产品或服务名称
<br/>可能需要收集必要的个人信息才能得以实现。当您撤回同意时，我们将无法继续为您提供撤回同意所对应的服务，但不会影响此前基于您授权而开展的个人信息处理服务。
<br/>6.5.注销您的账户
<br/>6.5.1.您可以通过以下方式随时注销您此前注册的账户：
<br/>（1）线上注销
<br/>（2）联系客服
<br/>6.5.2.在您注销账号后，该账号下的内容、信息、数据、记录等将会被删除或匿名化处理（但法律法规另有规定或监管部门另有要求的除外）。同时，账号一旦注销完成，将无法恢复。
<br/>6.6.响应您的上述请求
<br/>6.6.1.如您无法按照上述方式行使权力，您可以采用本政策载明的联系方式与我们联系。为保障您的账户安全，您向我们提出上述请求时，我们可能会先要求您验证自己的身份，然后再处理您的请求。
<br/>6.6.2.对于您的合理请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定的成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
<br/>6.6.3.响应情形的例外
<br/>在以下情形中，我们将无法响应您的请求：
<br/>(1)与我们履行法律法规规定的义务相关的。
<br/>(2)与国家安全、国防安全直接相关的。
<br/>(3)与公共安全、公共卫生、重大公共利益直接相关的。
<br/>(4)与刑事侦查、起诉、审判和执行判决等直接相关的。
<br/>(5)我们有充分证据表明个人信息主体存在主观恶意或滥用权利的。
<br/>(6)出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。
<br/>(7)响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的。
<br/>(8)涉及商业秘密的。

                        </p>
                        <p className='mt-0'>
                        7.我们如何处理未成年人的个人信息
<br/>7.1.我们非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品和服务前，应事先取得您监护人的同意。我们将根据国家相关法律法规的规定保护未成年人的个人信息。
<br/>7.2.我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
<br/>7.3.如果有事实证明未成年人在未取得监护人同意的情况下使用了我们的产品和服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
<br/>7.4.对于未满14周岁的未成年人，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。
<br/>7.5.若您为未满14周岁的未成年人的父母或其他监护人，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。

                        </p>
                        <p className='mt-0'>
                        8.本隐私政策如何更新
<br/>8.1.我们的隐私政策可能会适时发生变更。我们会在
<br/>请填充平台名称
<br/>上发布对本隐私政策所做的任何变更。对于重大变更，我们会提供更为显著的通知，如通过
<br/>在浏览页面做特别提示等方式
<br/>，说明隐私政策的具体变更内容。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。
<br/>8.2.本隐私政策所指的重大变更包括但不限于：
<br/>8.2.1.我们的服务模式发生重大变化。
<br/>8.2.2.个人信息共享、转让或公开披露的主要对象发生变化。
<br/>8.2.3.您参与个人信息处理方面的权利及其行使方式发生重大变化。
<br/>8.2.4.我们的联系方式及投诉渠道发生变化。
<br/>8.2.5.个人信息安全影响评估报告表明存在高风险时。
<br/>8.3.我们会将本隐私政策的旧版本存档，供您查阅。

                        </p>
                        <p className='mt-0'>
                        9.如何联系我们
                        <br/>9.1.如果您对本隐私政策有任何疑问、意见或建议，请通过以邮箱meric@redhousegecko.com与我们联系。
                        <br/>9.2.一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。

                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

PrivacyBody.propTypes = propTypes;
PrivacyBody.defaultProps = defaultProps;

export default PrivacyBody;