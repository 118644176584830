import React from 'react';

import Intro from '../pages/about/Intro';
import Culture from '../pages/about/Culture';
import Certificate from '../pages/about/Certificate';

const AboutUs = () => {

    return (
        <>
            <Intro />
            <Culture />
            <Certificate />
        </>
    );
}

export default AboutUs;