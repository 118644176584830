import React from 'react';
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
const BreadCrumb = ({
    children,
    className,
    status,
    ...props}) => {
  
    const { t } = useTranslation();
  
    return (
      <section className={className}>
        <div className='container'>
          <h3 className='mt-12 mb-12 bread-crumb'><Link to='/'>{t('header.brandName')}</Link>/ {children} </h3>
          </div>
      </section>
    );
  };

  
  export default BreadCrumb;