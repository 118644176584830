import React from 'react';

// import sections
import Blueprint from '../pages/home/Blueprint';
import HomeOverview from '../pages/home/HomeOverview';
import HomeTech from '../pages/home/HomeTech';
import Muscle from '../pages/home/Muscle';
import InTouch from '../pages/home/InTouch';
//import "antd/dist/antd.css";
const Home = () => {
  
  return (
    <>
      <Blueprint className="illustration-section-01" />
      <HomeOverview />
      <HomeTech invertMobile topDivider imageFill className="illustration-section-02" />
      <Muscle topDivider />
      <InTouch split />
    </>
  );
}

export default Home;