import React from 'react';
import classNames from 'classnames';

import Image from '../../elements/Image';
import LazyLoad from 'react-lazyload';
const CertFragment = ({
    children,
    imgSrc,
    height,
    certName,
    className,
    status,
    ...props }) => {
        const classes = classNames(
            'tiles-item',
            className
          );


    return (
        <div  className={classes} data-reveal-delay="200">
            <div class="tiles-item-inner">
                <div class="features-tiles-item-header mb-24">
                    <LazyLoad height={height||200}>
                    <Image className="img-icon" src={imgSrc} width={460} alt="certifcate" />
                    </LazyLoad>
                </div>
                <div class="features-tiles-item-content">
                    <p class="m-0 text-sm">{certName}</p>
                </div>
            </div>
        </div>
    );
};


export default CertFragment;