import React from 'react';
import classNames from 'classnames';
import { WhatsAppOutlined, MailOutlined,FacebookOutlined } from '@ant-design/icons';
import Image from '../../elements/Image';
import AppQrcode from '../../../assets/images/down_qrcode.png'
const SocialCount = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-social',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://www.facebook.com/profile.php?id=100082689636263" title="facebook">
            <FacebookOutlined> </FacebookOutlined>
          </a>
        </li>
        <li>
          <a href="whatsapp://send?phone=8618938670975" title="whatsapp">
            <WhatsAppOutlined> </WhatsAppOutlined>
          </a>
        </li>
        <li>
          <a href="mailto:meric@redhousegecko.com" title="mail">
            <MailOutlined> </MailOutlined>
          </a>
        </li>
        <li>
          {/*<a href="https://u.wechat.com/EP8JZ7ghclqGs0Gae1QS840" target="_blank" title="扫描微信二维码">
            <Image
              src={require('./../../../assets/images/wechat-qrcode.png')}
              style={{ "width": 80, "height": 80, color: '#6163FF' }}
            />
          </a>*/}
          <a href="https://img.redgecko.cn/media_data/apk/spring-release.apk" rel="noopener noreferrer" target="_blank" title="扫描二维码下载APP">
            <Image
              // src="/images/app-qrcode-320.png"
              src={AppQrcode}
              style={{ "width": 80, "height": 80 }}
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SocialCount;