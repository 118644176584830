import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './fragment/Logo';
import Image from '../elements/Image';
import FooterNav from './fragment/FooterNav';
import SocialCount from './fragment/SocialCount';
import { useTranslation } from 'react-i18next'
const propTypes = {
  isDefaultLogo: PropTypes.bool,
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  // isDefaultLogo: false,
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );
  const { t } = useTranslation();
  let beianString, policyString, domain = document.domain;
  if (domain.indexOf("redgecko.cn") !== -1) {
    beianString = "粤ICP备2022064692号 - 2";
  } else {
    beianString = "粤ICP备2022064692号 - 1";
    policyString = ". 粤公网安备 44030602006916号";
  }
  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Logo {...props} />
            <SocialCount />
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav />
            <div className="footer-copyright">{t('footer.copyright')}
              <a href="http://www.gsxt.gov.cn/index.html">{t('footer.company')}</a>{t('footer.right')}</div>

          </div>
          <div className="footer-beian"><a href="https://beian.miit.gov.cn/">
            {beianString}
          </a>
            {policyString &&
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602006916">
                <Image className="beian-icon"
                  src={require('./../../assets/images/beian.svg')}
                  width={20}
                  height={20}
                  alt="备案图标" />
                <span className='beian-id'>{policyString}</span></a>
            }
          </div>
        </div>
      </div>
      <Image  hidden src={"/gecko/visit/add?rd="+Math.random()}
                  width={1}
                  height={1}
                  alt="count" />
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;