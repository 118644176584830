
import React from 'react';
import { SectionTilesProps } from '../../utils/SectionProps';
import classNames from 'classnames';
import BreadCrumb from '../../components/layout/fragment/BreadCrumb';
import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import { useTranslation } from 'react-i18next'
import FormHint from '../../components/elements/FormHint';
import Validator from '../../utils/Validator';
import { message } from 'antd';


import axios from 'axios';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const SupplierAdd = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const {t}=useTranslation();

  const outerClasses = classNames(
    'contact section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    'contact-split'
  );
  const onsubmit = () => {
    const email = document.getElementById("email").value;
    let flag = Validator([{ type: "mail" }], email);

    if (flag === false) {
      message.error(t('tips.emailFormat'));
      return false;
    }
    const telephone = document.getElementById("telephone").value;
     flag = Validator([{ type: "tel" }], telephone);

    if (flag === false) {
      message.error(t('supplier.add.telephoneFormat'));
      return false;
    }
    const content = document.getElementById("content").value;

    const sup_name = document.getElementById("sup_name").value;
    const addr = document.getElementById("addr").value;
    if (!content && !sup_name) {
      message.error(t("supplier.add.nameAndContentRequired"));
      return false;
    }
    axios.post('/gecko/supplier/add', { email, telephone, content, sup_name,addr }).then(function (resp) {
      message.success(t("tips.contactSuccessTips"));
    }).catch(function (error) {
      message.error(t("tips.submitFail"));
    });
  };
  return (
    <section
      {...props}
      className={outerClasses}>
      <div className='container'>
        <BreadCrumb>{t('supplier.add.pageTitle')}</BreadCrumb>
        <div className={innerClasses}>
          <FormHint className="default-form" >
            <Input label={t('supplier.add.name') } className="input input-w200" id="sup_name" />
          </FormHint>
          <FormHint className="default-form" >
            <Input label={ t('supplier.add.email')} className="input input-w200" id="email" />
          </FormHint>
          <FormHint className="default-form" >
            <Input label={t('supplier.add.telephone')} placeholder={t('supplier.add.telFormat')} className="input input-w200" id="telephone" />
          </FormHint>
          <FormHint className="default-form" >
            <Input label={t('supplier.add.addr')} className="input input-w200" id="addr" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Input label={t('supplier.add.content')} type="textarea" id="content" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Button className='button button-gap' type="primary" htmlType="submit" onClick={onsubmit}>
              {t('tools.btn.submit')}
            </Button>
          </FormHint>
        </div>
      </div>
    </section>
  );
};

SupplierAdd.propTypes = propTypes;
SupplierAdd.defaultProps = defaultProps;

export default SupplierAdd;