import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { BackTop } from 'antd';
import VerticalAlignTopOutlined from '@ant-design/icons';

import '../assets/scss/style.scss';

const LayoutDefault = ({ children }) => (
  <div className='is-boxed'>
    <Header navPosition="right" className="reveal-from-bottom"/>
    <main className="site-content">
      {children}
    </main>
    <Footer />
    <div>
      <BackTop visibilityHeight={300} />
      <div ><VerticalAlignTopOutlined > </VerticalAlignTopOutlined></div>
    </div>
  </div>

);

export default LayoutDefault;  