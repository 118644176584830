import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { message } from 'antd';
import Validator from '../../utils/Validator';
import axios from 'axios';
import {DoubleRightOutlined} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const InTouch = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const { t } = useTranslation();
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );
  const onSubscribe = () => {
    const email_phone = document.getElementById("newsletter").value;
    if (!email_phone) {
      message.error(t("tips.emailOrPhoneInput"));
      return false;
    }
    let objSubscribe = {};
    //email or phone must select one.
    let flag = Validator([{ type: "mail" }], email_phone);

    if (flag) {
      objSubscribe.email = email_phone;
    } else {
      flag = Validator([{ type: "mobile" }], email_phone);
      if (flag) {
        objSubscribe.phone_number = email_phone;
      } else {
        message.error(t("tips.format"));
        return false;
      }
    }

    if (!objSubscribe.email && !objSubscribe.phone_number) {
      message.error(t("tips.emailOrPhoneRequired"));
      return false;
    }
    axios.post('/gecko/subscribe/add', objSubscribe).then(function (resp) {
      message.success(t("tips.contactSuccessTips"));
    }).catch(function (error) {
      message.error(t("tips.submitFail"));
    });
  };
  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
            { t("index.it.inputLabel")}
            </h3>
          </div>
          <div className="cta-action">
            <div className="has-icon-right entity">
              <input id="newsletter" className='form-input' has-icon="right" placeholder={ t("index.it.placeholder")}>
              </input>
              <div className='subscribe' onClick={onSubscribe} ><DoubleRightOutlined > </DoubleRightOutlined></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

InTouch.propTypes = propTypes;
InTouch.defaultProps = defaultProps;

export default InTouch;