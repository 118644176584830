import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from './../../utils/SectionProps';
import SectionHeader from '../../components/sections/block/SectionHeader';
import SectionBlock from '../../components/sections/block/SectionBlock';
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const HomeOverview = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: t("index.ho.sh.title"),
    paragraph: t("index.ho.sh.paragraph"),
    imgSrc: t("index.ho.sh.imgSrc"),
    tail: t("index.ho.sh.tail")
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            <SectionBlock
              data={{
                "title": t("index.ho.sb.p1.title"),
                "icon": ConsoleSqlOutlined,
                "paragraph": t("index.ho.sb.p1.paragraph")
              }}

            />
            <SectionBlock
              data={{
                "title": t("index.ho.sb.p2.title"),
                "image": require("./../../assets/images/feature-tile-icon-01.svg"),
                "paragraph": t("index.ho.sb.p2.paragraph")
              }}
            />
            <SectionBlock
              data={{
                "title": t("index.ho.sb.p3.title"),
                "image": require("./../../assets/images/feature-tile-icon-04.svg"),
                "paragraph": t("index.ho.sb.p3.paragraph")
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

HomeOverview.propTypes = propTypes;
HomeOverview.defaultProps = defaultProps;

export default HomeOverview;