import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from '../../components/sections/block/SectionHeader';
import SplitItem from "../../components/sections/block/SplitItem";
import { useTranslation } from 'react-i18next';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const HomeTech = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const { t } = useTranslation();
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title:t("index.ht.title"), 
    paragraph: t("index.ht.paragraph")
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <SplitItem data=
              {{
                slogan:  t("index.ht.s1.slogan"),
                title:  t("index.ht.s1.title"),
                content: t("index.ht.s1.content"),
                imgSrc: t("index.ht.s1.imgSrc")
              }}
              className='reveal-from-left'
            />
            <SplitItem data=
              {{
                slogan:  t("index.ht.s2.slogan"),
                title:  t("index.ht.s2.title"),
                content: t("index.ht.s2.content"),
                imgSrc: t("index.ht.s2.imgSrc")
              }}

              className='reveal-from-right'

            />
            <SplitItem data=
              {{
                slogan:  t("index.ht.s3.slogan"),
                title:  t("index.ht.s3.title"),
                content: t("index.ht.s3.content"),
                imgSrc: t("index.ht.s3.imgSrc")
              }}
              className='reveal-from-left'
            />

          </div>
        </div>
      </div>
    </section>
  );
}

HomeTech.propTypes = propTypes;
HomeTech.defaultProps = defaultProps;

export default HomeTech;