
import React from 'react';
import { SectionTilesProps } from './../../utils/SectionProps';
import classNames from 'classnames';
//import { Button, Checkbox, Form, Input } from 'antd';
import Input from '../../components/elements/Input';
import Button from '../../components/elements/Button';
import FormHint from '../../components/elements/FormHint';
import Validator from '../../utils/Validator';
import BreadCrumb from '../../components/layout/fragment/BreadCrumb';
import { message } from 'antd';
import { useTranslation } from 'react-i18next'
//import "antd/dist/antd.css";

import axios from 'axios';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const ContactItem = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const { t } = useTranslation();
  const outerClasses = classNames(
    'contact section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    'contact-split'
  );
  const onsubmit = (values) => {
    const email = document.getElementById("email").value;
    let flag = Validator([{ type: "mail" }], email);

    if (flag === false) {
      message.error(t('tips.emailFormat'));
      return false;
    }
    const phone_number = document.getElementById("phone").value;
    flag = Validator([{ type: "mobile" }], phone_number);

    if (flag === false) {
      message.error(t('tips.phoneFormat'));
      return false;
    }
    const content = document.getElementById("content").value;

    const user_name = document.getElementById("user_name").value;

    if (!email && !phone_number) {
      message.error(t("tips.emailAndPhoneRequired"));
      return false;
    }
    axios.post('/gecko/intentions/add', { email, phone_number, content, user_name }).then(function (resp) {
      message.success(t("tips.contactSuccessTips"));
    }).catch(function (error) {
      console.log(error);
      message.error(t("tips.submitFail"));
    });
  };
  return (
    <section
      {...props}
      className={outerClasses}>
      <div className='container'>
        <BreadCrumb>{t('contact.contactItem.contact')}</BreadCrumb>
        <div className={innerClasses}>
          <FormHint className="contact-slogan" >
            <Input label={t('contact.contactItem.email')} className="input input-w200" id="email" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Input label={t('contact.contactItem.phone')} className="input input-w200" id="phone" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Input label={t('contact.contactItem.name')} className="input input-w200" id="user_name" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Input label={t('contact.contactItem.appeal')} type="textarea" id="content" />
          </FormHint>
          <FormHint className="contact-slogan" >
            <Button className='button button-gap' type="primary" htmlType="submit" onClick={onsubmit}>
              {t('tools.btn.submit')}
            </Button>
          </FormHint>
        </div>
      </div>
    </section>
  );
};

ContactItem.propTypes = propTypes;
ContactItem.defaultProps = defaultProps;

export default ContactItem;