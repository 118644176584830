import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "./../../utils/SectionProps";
import SectionHeader from "../../components/sections/block/SectionHeader";
// import SectionBlock from "../../components/sections/block/SectionBlock";
// import { ConsoleSqlOutlined } from "@ant-design/icons";
// import Image from '../../components/elements/Image';
import LogoSpring from "../../assets/images/logo_round_192.png";
import ImgQuality from "../../assets/images/s_mall-3.png";
import ImgQuantity from "../../assets/images/s_mall-5.png";
import { useTranslation } from "react-i18next";
import SplitItem from "../../components/sections/block/SplitItem";
// import classNames from "classnames";
const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const SpringHouse = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  pushLeft,
  ...props
}) => {
  const { t } = useTranslation();
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-100",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content mt-50",
    pushLeft && "push-left"
  );
  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );
//   const roundedClasses = classnames("rounded", "center-content");
  //   const sectionHeader = {
  //     title: t("index.ho.sh.title"),
  //     paragraph: t("index.ho.sh.paragraph"),
  //     imgSrc: t("index.ho.sh.imgSrc"),
  //     tail: t("index.ho.sh.tail"),
  //   };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          {/* <div style={{width:'100%',textAlign:"center"}}>
          <Image
              className="has-shadow"
              style={{raduis:"20px"}}
              src={LogoSpring}
              alt="logo"
              width={100}
              height={100} />
          </div> */}

          <SectionHeader
            data={{
              title: t("header.spring"),
              logo: LogoSpring,
              paragraph: t("spring.intro"),
            }}
            className="center-content"
          />
        </div>
        <div className={tilesClasses}>
          {/* <SectionBlock
              data={{
                "title": t("header.spring"),
                "image": LogoSpring,
                "paragraph": t("spring.intro")
              }}

            /> */}
          {/* <SectionBlock
              data={{
                "title": t("index.ho.sb.p2.title"),
                "image": require("./../../assets/images/feature-tile-icon-01.svg"),
                "paragraph": t("index.ho.sb.p2.paragraph")
              }}
            />
            <SectionBlock
              data={{
                "title": t("index.ho.sb.p3.title"),
                "image": require("./../../assets/images/feature-tile-icon-04.svg"),
                "paragraph": t("index.ho.sb.p3.paragraph")
              }}
            /> */}
          <div className={splitClasses}>
            <SplitItem
              data={{
                slogan: t("spring.s1.slogan"),
                title: t("spring.s1.title"),
                content: t("spring.s1.content"),
                imgSrc: ImgQuality,
                width: 200,
                height: 200,
              }}
              className="reveal-from-left"
            />
            <SplitItem
              data={{
                slogan: t("spring.s2.slogan"),
                title: t("spring.s2.title"),
                content: t("spring.s2.content"),
                imgSrc: ImgQuantity,
                width: 200,
                height: 200,
              }}
              className="reveal-from-right"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

SpringHouse.propTypes = propTypes;
SpringHouse.defaultProps = defaultProps;

export default SpringHouse;
