import React from 'react';


import AddTender from '../pages/solutions/AddTender';

const Tender = () => {

    return (
        <>
            <AddTender />
        </>
    );
}

export default Tender;