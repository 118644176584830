import React,{useEffect} from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import RouteChangeRefresher from "./utils/RouteChangeRefresher"
// import { useLocation } from 'react-router-dom';
// import { BrowserRouter } from 'react-router';
// import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./i18n/config";
import "./App.css";

const history = createBrowserHistory();
// const history = createHistory();
// const root = createRoot(document.getElementById("root")); //ReactDOM.createRoot(document.getElementById('root'))
// root.render(
//   <Router history={history}>
//     <App />
//   </Router>
// );

// RouteChangeRefresher(history)
ReactDOM.render(
  <Router history={history}>
    <RouteChangeRefresher history={history}></RouteChangeRefresher>
    <App />
  </Router>,
  document.getElementById('root')
);
// ReactDOM.render(
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>,
//     document.getElementById('root')
//   );
serviceWorker.unregister();
