import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

import { useTranslation } from 'react-i18next';
import Image from '../../components/elements/Image';
const propTypes = {
    ...SectionProps.types
}

const defaultProps = {
    ...SectionProps.defaults
}

const Intro = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {

    const { t } = useTranslation();
  
    const outerClasses = classNames(
        'about section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    return (
        <section
            {...props}
            className={outerClasses}>
            <div className="container">
                <div className="features-split center-content">
                    <h3 className="mt-48 mb-16">{t("about.intro.title")}</h3>
                    <div className='container-xs'>
                        <h6 className='mt-0 mb-16'>
                        {t("about.intro.introText.p1")}
                        </h6>
                        <Image className="mb-24" src="/images/20220828150609.png"
                        srcSet="/images/20220828150609-640 640w,
                                /images/20220828150609-960.png 960w,
                                /images/20220828150609.png 1080w"
                        sizes="(max-width: 640px) 100vw, 
                        (max-width: 960px) 75vw, 
                        960px"
                        alt="company introduce image" />
                        <p className='mt-0'>
                        {t("about.intro.introText.p2")}
                        </p>
                        <p className='mt-0'>
                        {t("about.intro.introText.p3")}
                        </p>
                        <p className='mt-0'>
                        {t("about.intro.introText.p4")}
                        </p>
                        <p className='mt-0'>
                        {t("about.intro.introText.p5")}
                        </p>
                        <p className='mt-0'>
                        {t("about.intro.introText.p6")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

Intro.propTypes = propTypes;
Intro.defaultProps = defaultProps;

export default Intro;