import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import Icon from './../../../assets/images/logo_108x108.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
const defaultProps = {
  // "is-default-logo": true,
}
const propTypes = {
  // "is-default-logo": PropTypes.bool
}
const Logo = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );
const {t}=useTranslation();
  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link className='link' to="/">
          <Image className='logo'
            src={Icon}
            alt="red gecko logo"
            width={32}
            height={32} />
            <span className='brand-name'>{t('header.brandName')}</span>
        </Link>
      </h1>
    </div>
  );
}
Logo.propTypes = propTypes;
Logo.defaultProps =defaultProps;
export default Logo;