import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import ReactGA from "react-ga";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
// import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
// import { withRouter } from "react-router";
// Views
import Home from "./views/Home";
import Contact from "./views/Contact";
import AddQuotation from "./views/AddQuotation";
import Tender from "./views/Tender";
import AboutUs from "./views/AboutUs";
import LayoutWhite from "./layouts/LayoutWhite";
import SupplierAdd from "./pages/supplier/SupplierAdd";
import PrivacyBody from "./pages/privacy/PrivacyBody";
import SpringHouse from "./views/SpringHoust";
import { message } from "antd";

// import { useSearchParams } from "react-router-dom";

// const [searchParams] = useSearchParams();

// 获取单个查询参数
// let lng = searchParams.get("lng");
// let lng = "zh";
// if (lng && "_zh_en".indexOf(lng) !== -1) {
//   localStorage.setItem("lng", lng);
// }
// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

message.config({
  top: 150,
  duration: 2,
  maxCount: 3,
  rtl: true,
  prefixCls: "",
});

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add("is-loaded");
    childRef.current.init();
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <ScrollReveal
      ref={childRef}
      children={() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/web" component={Home} layout={LayoutDefault} />
          <AppRoute
            exact
            path="/web/contact"
            component={Contact}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/quotations"
            component={AddQuotation}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/tender"
            component={Tender}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/about"
            component={AboutUs}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/supplier"
            component={SupplierAdd}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/privacy"
            component={PrivacyBody}
            layout={LayoutWhite}
          />
          <AppRoute
            exact
            path="/web/spring"
            component={SpringHouse}
            layout={LayoutWhite}
          />
        </Switch>
      )}
    />
  );
};

export default App;
